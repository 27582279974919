@use 'src/styles/mixins';
@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-pages-start {
  &__sub-header-placeholder {
    width: 100%;
    height: 147px;
    background-color: colors.$primary-dark;
    display: flex;
    align-items: center;
    justify-content: center;

    & h2 {
      font-size: 30px;
      font-weight: 500;
      color: colors.$white;
    }

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      height: 87px;

      & h2 {
        font-size: 26px;
      }
    }
  }

  &__header {
    width: 100%;
    height: 140px;
    background-color: colors.$black;
    display: flex;
    align-items: center;
    justify-content: start;

    & h2 {
      font-size: 30px;
      font-weight: 500;
      color: colors.$white;
    }

    & img {
      height: 103px;
    }

    &--line {
      border-left: 1px solid colors.$white;
      height: 50px;
      width: 0;
      margin-right: 30px;
    }

    p {
      color: colors.$white;
      font-size: 24px;
      font-weight: 600;
    }

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      height: 87px;
      padding-top: 10px;

      & img {
        height: 64px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}

.berg-components-billboard {
  &__image {
    max-width: 90vw;
    max-height: 80vh;
  }

  &__close-btn {
    top: 0;
    right: 0;
    padding: 8px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
