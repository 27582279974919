@use 'src/styles/colors';

.berg-page-404-picture {
  height: 50%;
  margin: 15px;
  background-color: black;
}

.berg-page-404-text {
  font-size: 18px;
  font-weight: 400;
  margin: 15px;
}

.berg-page-404-button {
  width: 295px;
  height: 63px;
  background-color: colors.$blue;
  color: colors.$white;
  font-size: 14px;
  font-weight: 600;
  margin: 15px;
  text-decoration: none;
}

.berg-page-404-button a {
  text-decoration: none;
}
