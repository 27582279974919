@use 'src/styles/colors';

.berg-components-options-switch {
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 1px colors.$light-grey;
  border-radius: 5px;
  overflow: hidden;
}

.berg-components-options-switch-indicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: colors.$primary-light;
  transition: transform 0.3s, width 0.3s;
}

.berg-components-options-switch-option {
  padding: 6px 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.berg-components-options-switch-option[aria-disabled='true'] {
  pointer-events: none;
  cursor: default;
  color: colors.$light-grey;
}
