@use 'src/styles/colors';

.berg-devider {
  margin-top: 1rem;
  height: 1px;
  width: 100%;
  &__grey {
    background-color: colors.$grey;
  }
  &__ligth-grey {
    background-color: colors.$light-grey;
  }
  &__lighter-grey {
    background-color: colors.$lighter-grey;
  }
}
