@use 'src/styles/vars';

.berg-checkbox {
  cursor: pointer;
  vertical-align: middle;
  display: flex;

  & input {
    cursor: pointer;
    appearance: auto;
    margin: 0.3rem;
  }
  & label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    padding-right: 1vw;
    text-align: left;
    color: #4e4e4e;
  }
}
