@use 'src/styles/colors';

.berg-components-menu {
  &__header {
    font-size: 30px;
    font-weight: 700;
    color: colors.$primary-dark;
    text-transform: uppercase;
    margin-bottom: 30px;
    grid-area: title;
  }

  &__subtitle {
    font-size: 23px;
    font-weight: 700;
    color: colors.$black;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 15px;
    grid-area: title;
  }
}

.berg-components-menu-lists .berg-components-menu-list{
  display: none;
}

.berg-components-menu-list[data-berg-active='true']{
  display: block;
}

.berg-components-menus{ 
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: 1fr;
  grid-auto-flow: dense;
  grid-template-areas:
    'title title'
    'menu menu';
}

.berg-components-menu-list {
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__item:nth-child(2):nth-last-child(odd) {
    grid-column: 1;
  }

  &__item:nth-last-child(odd) {
    grid-column: 1;
  }

  &__item:nth-last-child(even) {
    grid-column: 2;
  }

  &__item:first-child {
    grid-column: 1;
  }
  &__item:nth-child(2) {
    grid-column: 2;
  }

  &__label {
    text-align: start;

    & > div:first-child {
      text-transform: uppercase;
      font-size: 17px;
      font-weight: 600;
    }

    & > div:last-child {
      font-size: 17px;
      font-weight: 400;
    }
  }

  &__price {
    font-size: 17px;
    font-weight: 600;
    justify-self: end;
    padding-right: 35px;
  }
}

@media (max-width: 480px) {
  .berg-components-menus{
    display: block;
    padding: 15px;
  }

  .berg-components-menu-list__item {
    margin-bottom: 30px;
    grid-template-columns: 80% 20%;
  }

  .berg-components-menu-list__price {
    padding-right: 0;
}
}