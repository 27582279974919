@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';

.berg-components-parking {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 284px;
    background-color: colors.$white;
    margin-bottom: 24px;

    @for $i from 1 through 99 {
      &:nth-child(#{$i}) {
        z-index: $i * -1 + 100;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      flex-direction: row;
    }
  }

  &__picture {
    position: relative;
    overflow: hidden;
    width: 100%;

    img {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      img {
        width: 50%;
        height: 50%;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      width: 34%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__content {
    flex: 1;

    display: grid;
    padding-bottom: 26px;
    grid-template-rows: repeat(auto, 6);
    gap: 20px;
    justify-items: center;
    grid-template-areas:
      'title'
      'choices'
      'info'
      'line'
      'sum'
      'submit';

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      padding: 20px 26px 26px 40px;
      grid-template-rows: auto 1fr auto;
      grid-template-areas:
        'title title'
        'choices sum'
        'info submit';
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      gap: unset;
    }
  }

  &__title {
    grid-area: title;

    justify-self: stretch;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      text-align: start;
    }
  }

  &__line {
    grid-area: line;
    width: 100%;
    height: 1px;
    border: 1px colors.$light-grey solid;
  }

  &__choices {
    grid-area: choices;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
    gap: 20px;
    justify-self: start;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      flex-direction: row;
    }

    & > div {
      display: flex;
      align-items: center;

      &:first-child {
        z-index: 1;
      }

      & > label {
        margin-right: 12px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .berg-components-dropdown-wrapper {
      width: 250px;
    }

    .Dropdown-option.is-selected {
      color: colors.$white;
      background-color: colors.$primary-dark;
    }
  }
  &__choices + &__line {
    display: block;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      display: none;
    }
  }

  &__choice-label-dropdown {
    align-self: center;
    margin-right: 20px;
  }

  &__sum {
    grid-area: sum;
    align-self: end;
    font-size: 24px;
    font-weight: 600;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
      align-self: center;
    }
  }

  &__submit {
    grid-area: submit;
    background-color: colors.$primary-dark;
    color: colors.$white;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    line-height: 1;
    white-space: nowrap;
    height: 48px;
    align-self: center;
    border-radius: 8px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
    }

    &:disabled {
      background-color: colors.$light-grey;
    }
  }

  &__info {
    grid-area: info;
    display: flex;
    align-items: center;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: start;
    }
  }

  &__info p {
    font-size: 12px;
    color: colors.$grey;
    margin: 0 15px;
    text-align: start;
    line-height: 20px;
  }

  &__number-ID {
    grid-area: info;

    display: flex;
    background-color: colors.$gray-150;
    justify-content: start;
    align-items: center;
    font-size: 17px;
    border-radius: 0 2px 2px 0;
    justify-self: start;
    width: 312px;

    &[aria-disabled='true'] {
      opacity: 0.6;
    }

    div {
      color: colors.$grey;
      text-align: start;
    }

    button {
      color: colors.$black;
      font-weight: 500;
      font-size: 13px;
      text-align: start;
      padding-left: 13px;
    }

    img {
      height: 48px;
      cursor: pointer;
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(2em);
      content: '';
    }
  }
}

.berg-components-parking__content .berg-components-tooltip {
  grid-area: submit;
  justify-self: end;
  align-self: center;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    justify-self: center;
  }
}
