@use 'src/styles/colors';
@use 'src/styles/vars';

footer > section {
  display: grid;
  grid-template-rows: auto auto auto auto;
}

// .footer-component-navigation {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
// }

// .footer-component-navigation .berg-components-menu-item {
//   margin: 5px 10px 5px 0;
// }

// .footer-component-navigation .berg-components-navigation-dropdown-list {
//   top: 27px;
// }

// .footer-component-navigation .berg-components-navigation-dropdown-list {
//   background: colors.$white;
//   color: colors.$black;
// }

footer hr {
  padding: 0 10vw 0 10vw;
  margin-top: 2rem;
  height: 2px;
  border: 1px white solid;
}

.footer-component-company-data {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  p {
    color: colors.$white;
    font-size: 14px;
    font-weight: 500;
  }

  > p:first-child {
    color: colors.$white;
    font-size: 14px;
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  button {
    display: inline-flex;
    padding: 6px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid colors.$white;
    color: colors.$white;
    background-color: colors.$black;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px; /* 200% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-left: 20px;
  }
}

.footer-component-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 500;

  &__rules {
    display: flex;

    & div {
      display: flex;
      flex-direction: row;
      text-align: start;
      width: 100%;
      gap: 20px;
    }
  }

  &__berg {
    display: flex;
    align-self: start;
  }
}

.footer-component-header {
  margin: 40px 0 20px 0;
  display: flex;

  & p {
    font-size: 14px;
    font-weight: 400;
  }

  & a {
    text-decoration: none;
    margin: 0 15px;
    align-self: center;
    font-size: 14px;
    font-weight: 400;
  }

  &__partners {
    display: flex;
    align-items: center;
  }
}

.footer-component-header img {
  height: 103px;
}

.footer-component-line {
  border-left: 1px solid colors.$white;
  height: 64px;
  width: 0;
  margin: 0 30px;

  &__rules {
    border-left: 1px solid colors.$white;
    width: 0;
    margin: 0 30px;
  }
}

.footer-component-footer a {
  text-decoration: none;
}

.footer-component-footer-copyright {
  margin-left: auto;
  margin-right: 10px;
  align-self: center;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .footer-component-footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: center;
    gap: 15px;

    &__rules {
      gap: 10px;
      width: 100%;
      justify-content: center;
    }

    & div {
      display: flex;
      justify-content: center;
    }
  }

  .footer-component-footer-copyright {
    justify-content: center;
    align-self: center;
  }

  .footer-component-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__partners {
      padding-top: 12px;
    }
  }

  .footer-component-company-data {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: start;

    button {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) and (min-width: map-get(vars.$breakpoints, 'small')) {
  .footer-component-footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: center;
    gap: 15px;

    &__rules {
      gap: 10px;
      width: 100%;
      justify-content: center;
    }

    & div {
      display: flex;
      justify-content: center;
    }
  }

  .footer-component-footer-copyright {
    justify-content: center;
    align-self: center;
  }

  .footer-component-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__partners {
      padding-top: 12px;
    }
  }

  .footer-component-company-data {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;

    button {
      margin-left: 0;
    }
  }
}
