@use 'src/styles/vars';

.berg-components-alert {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: white;
    opacity: 0.7;
  }

  svg {
    margin: 0 0.5rem;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;

    span {
      font-weight: 700;
    }
  }
}
