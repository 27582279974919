@use 'src/styles/colors';

.berg-components-navigation-dropdown {
  display: flex;
  justify-content: center;
  position: relative;
  background: none;
}

.berg-components-navigation-dropdown[aria-expanded='true'] button {
  color: colors.$white-alpha-70;
}

.berg-components-navigation-dropdown button {
  display: flex;
  align-items: center;
  background: none;
}

.berg-components-navigation-dropdown button svg {
  pointer-events: none;
  margin-left: 6px;
}

.berg-components-navigation-dropdown-list {
  display: none;
  position: absolute;
  left: 0;
}

.berg-components-navigation-dropdown-list li a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
}

.berg-components-navigation-dropdown-list li:not(:last-child) {
  border-bottom: 1px solid colors.$white;
}

.berg-components-navigation-dropdown-list li a:hover {
  background-color: colors.$black-alpha-20;
}

.berg-components-navigation-dropdown-list.visible {
  display: block;
}
