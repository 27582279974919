@use 'src/styles/colors';
@use 'src/styles/vars';

.order-status-wrapper {
  &__payment-status {
    font-size: 1.25rem;
    font-weight: 600;
    color: colors.$green;
  }
  &__item-header {
    font-weight: 600;
    font-size: 1.25rem;
  }

  &__payment-box {
    background-color: #f7f7f7;
    width: 100%;
    padding-left: 1rem;
  }
  
  &__buttons {
    width: 100%;
  }

  &__thank-you-message {
    font-weight: 600;
    font-size: 1.125rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__thanks-for-order {
    align-self: center;
    margin-top: 2rem;
  }

  .order-status-wrapper__thank-you-message {
    margin: 1rem 0;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-columns: auto;
  }
}

.order-status-label {
  font-size: 1.125rem;
  font-weight: 500;
  color: colors.$orange-500;
  margin-top: 2rem;
  text-align: start;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .order-status-wrapper__buttons {
    display: flex;
    flex-direction: column-reverse;

    button {
      align-self: center;
    }

    div {
      align-self: center;
    }
  }
}
