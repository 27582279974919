@use 'src/styles/colors';

.berg-components-tooltip {
  z-index: 1;
}

[data-tooltip] {
  position: relative;
  line-height: 0;
  padding: 5px;
  margin: -5px;

  &,
  & * {
    cursor: help;
  }
}
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s cubic-bezier(0.5, 1, 0.25, 1);
  z-index: 1;
}
[data-tooltip]:before {
  padding: 10px 16px;
  width: auto;
  white-space: nowrap;
  border-radius: 8px;
  background-color: colors.$primary-dark;
  color: colors.$white;
  filter: drop-shadow(0px 0.8px 1.5px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.2));
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
}
[data-tooltip]:after {
  border: 8px solid transparent;
  width: 0;
  content: '';
  font-size: 0;
  line-height: 0;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
[data-tooltip].t-top:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 8px;
  transform: translateX(-50%);
}
[data-tooltip].t-top:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid colors.$primary-dark;
  border-bottom: none;
}
[data-tooltip].t-top:hover:before,
[data-tooltip].t-top:hover:after {
  transform: translateX(-50%) translateY(-5px);
}
[data-tooltip].t-right:before {
  top: 50%;
  left: 100%;
  margin-left: 8px;
  transform: translateY(-50%);
}
[data-tooltip].t-right:after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-right: 8px solid colors.$primary-dark;
  border-left: none;
}
[data-tooltip].t-right:hover:before,
[data-tooltip].t-right:hover:after {
  transform: translateX(5px) translateY(-50%);
}
[data-tooltip].t-bottom:before {
  top: 100%;
  left: 50%;
  margin-top: 8px;
  transform: translateX(-50%);
}
[data-tooltip].t-bottom:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid colors.$primary-dark;
  border-top: none;
}
[data-tooltip].t-bottom:hover:before,
[data-tooltip].t-bottom:hover:after {
  transform: translateX(-50%) translateY(5px);
}
[data-tooltip].t-left:before {
  top: 50%;
  right: 100%;
  margin-right: 8px;
  transform: translateY(-50%);
}
[data-tooltip].t-left:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-left: 8px solid colors.$primary-dark;
  border-right: none;
}
[data-tooltip].t-left:hover:before,
[data-tooltip].t-left:hover:after {
  transform: translateX(-5px) translateY(-50%);
}
