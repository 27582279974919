@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-contact-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: url('../../assets/images/background-contact-info.jpg');
  background-position: left;
  background-repeat: repeat;
  background-size: cover;
  text-align: start;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: colors.$navy;
    opacity: 0.9;
  }
}

.berg-components-contact-details {
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 50px 20px;
  z-index: 1;
}

.contact-details-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 290px;
  z-index: 1;

  &:first-child {
    margin-right: 16px;
  }
}

.contact-details-content strong {
  margin-top: 10px;
  color: colors.$orange-500;
  text-align: initial;
}

.contact-details-content strong:not(:first-child) {
  margin-top: 10px;
}

.contact-details-bold-link {
  font-weight: 700;
  color: colors.$white;
  line-height: 2em;
  text-align: initial;
  text-decoration: none;
}

.contact-details-bold-link:hover {
  text-decoration: underline;
}

.contact-details-content {
  span,
  b {
    color: colors.$white;
    text-align: initial;
    font-size: 0.75rem;
  }
}
@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-contact-details-wrapper {
    flex: 1 0 50%;
  }
}
