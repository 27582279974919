@use 'src/styles/colors';

.berg-components-choice-number {
  background-color: colors.$primary-dark;
  color: colors.$white;
  padding: 25px;
  border-radius: 8px;
  border: 1px solid colors.$white;
}

.berg-components-choice-number-header {
  display: flex;
  align-items: center;
}

.berg-components-choice-number-text {
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  &.space {
    width: 70px;
  }

  &--under-3 {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 15px;
    line-height: 25px;
  }
}

.berg-components-choice-number-line {
  border: 0.1px colors.$white solid;
  margin: 10px -25px;
}

.berg-components-choice-number-btn {
  width: 79px;
  height: 35px;
  font-size: 12px;
  font-weight: 700;
  color: colors.$black;
  background-color: colors.$white;
}

.berg-components-choice-number-age-children {
  position: relative;
  display: flex;
  align-items: center;
  margin: 3px 0;

  @for $i from 1 through 99 {
    &:nth-child(#{$i}) {
      z-index: $i * -1 + 100;
    }
  }
}

.berg-components-choice-number-dropdown {
  width: 145px;
}

.berg-components-choice-number-dropdown .Dropdown-menu {
  position: absolute;
}

.berg-components-choice-number-number-counter {
  color: colors.$black;
}

.berg-components-choice-number-input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.berg-components-choice-number-error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  background-color: colors.$white;
  color: colors.$error-500;
  font-size: 11px;
  font-weight: 600;
  border-radius: 4px;
  padding: 2px;
  margin-top: 6px;
  gap: 15px;

  & svg {
    height: 1em;
  }
}
