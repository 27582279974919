@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-page-content-wrapper {
  padding: 5vh 70px 10vh 70px;
  background-color: colors.$white;
  max-width: 1430px;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    padding: 5vh 3vw 10vh 3vw;
  }
}
