@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-room-details {
  display: flex;
  justify-content: space-around;
  margin: 1rem 3rem 0 0;
}

.berg-components-room-details div {
  display: grid;
  grid-template-columns: auto auto;
}

.berg-components-room-details div label {
  padding: 0 2rem 0.5rem 0;
  color: colors.$grey;
}

@media (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-components-room-details {
    display: block;
    margin: 1rem 0 1rem 0;
  }

  .berg-components-room-details div:last-child {
    padding-left: 0;
  }
}

@media (max-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-room-details {
    margin: 1rem 2rem 0 2rem;
  }
}
