@use 'src/styles/colors';

.berg-components-collapse-list {
  button {
    font-weight: 500;
    color: colors.$gray-400;
    margin-top: 5px;
  }

  ol {
    font-weight: 500;
    color: colors.$gray-500;
    list-style-type: decimal;
    list-style-position: inside;

    li {
      list-style-type: decimal;
      margin: 5px 0;
      text-transform: uppercase;
    }
  }
}
