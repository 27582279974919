@use 'src/styles/colors';
@use 'src/styles/vars';

.payment-status-wrapper {
  &__payment-status {
    font-size: 1.25rem;
    font-weight: 600;
  }
  &__item-header {
    font-weight: 600;
    font-size: 1.25rem;
  }

  &__payment-box {
    background-color: colors.$gray-150;
    width: 55%;
    padding-left: 1rem;
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      width: 100%;
    }
  }
  &__buttons {
    width: 100%;
  }
  &__thank-you-message {
    font-weight: 600;
    font-size: 1.125rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-columns: auto;
  }
}

.payment-status-label {
  font-size: 1.125rem;
  font-weight: 500;
  color: colors.$polana-red;
  margin-top: 2rem;
  text-align: start;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .payment-status-wrapper__buttons {
    display: flex;
    flex-direction: column-reverse;

    button {
      align-self: center;
    }

    div {
      align-self: center;
    }
  }
}
