@use 'src/styles/vars';

.berg-stack {
  display: flex;
  flex-direction: row;

  &--column {
    flex-direction: column;
  }
  @media (min-width: 851px) {
    &--desktop-column {
      flex-direction: column;
    }
  }

  @media (min-width: 481px) and (max-width: map-get(vars.$breakpoints, 'medium')) {
    &--tablet-column {
      flex-direction: column;
    }
  }
  @media (min-width: 0px) and (max-width: map-get(vars.$breakpoints, 'small')) {
    &--mobile-column {
      flex-direction: column;
    }
  }
}
