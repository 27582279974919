@use 'src/styles/colors';
@use 'src/styles/vars';

.cart-products {
  &__product-wrapper {
    width: 100%;
  }
  &__product-header {
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
  }
  &__summary-header {
    font-size: 1.25rem;
    font-weight: 600;
    justify-self: start;
  }
  &__summary-header-location {
    font-size: 1.125rem;
    font-weight: 500;
    justify-self: start;
    color: colors.$almost-grey;
  }
  &__buttons {
    & > div:last-child {
      display: flex;
      gap: 1.25rem;
      margin-top: 1rem;

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        display: flex;
        flex-direction: column-reverse;
        align-self: center;
      }
    }
  }

  .category-header {
    font-weight: 500;
    font-size: 0.8125rem;
    justify-self: start;
    padding-left: 1rem;
  }
  .category-quantity {
    font-size: 1.1875rem;
    font-weight: 600;
    justify-self: center;
    color: colors.$light-grey;
  }
  .category-price-light {
    font-size: 1.1875rem;
    font-weight: 600;
    justify-self: end;
    color: colors.$light-grey;
  }
  .category-price {
    font-size: 1.1875rem;
    font-weight: 600;
    justify-self: end;
  }
  .category-price-location {
    font-size: 1.1875rem;
    font-weight: 500;
    justify-self: end;
    color: colors.$almost-grey;
  }
  .category-operations {
    justify-self: end;
  }
  .validity-date {
    font-style: italic;
    font-size: 0.75rem;
    color: colors.$light-grey;
    text-align: left;
  }
  .data-carrier {
    font-size: 0.75rem;
  }
}
