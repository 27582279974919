@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-about[data-berg-layout='leftPicture'] {
  text-align: left;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: auto;
  column-gap: 3rem;
}
.berg-components-about-special-text {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: left;
}
.berg-components-about-span {
  font-weight: 700;
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-about[data-berg-layout='leftPicture'] {
    padding: 0;
    grid-template-columns: 4fr 6fr;
  }
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-about {
    padding: 2rem 0;
  }
}

.berg-components-about-image {
  width: 100%;
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-about-main-item
    .berg-components-about[data-berg-layout='leftPicture']
    .berg-components-about-picture {
    grid-column: 1;
    grid-row: 1 / 4;
    align-self: center;
  }
}

.berg-components-about-main-item
  .berg-components-about[data-berg-layout='leftPicture']
  .berg-components-about-text {
  grid-row: 1;
  grid-column: 2;
}

.berg-components-about[data-berg-layout='rightPicture'] {
  text-align: left;
  padding: 1rem 0;
  grid-template-columns: auto;
  display: grid;
  column-gap: 3rem;
}

@media screen and (min-width: 1200px) {
  .berg-components-about[data-berg-layout='rightPicture'] {
    padding: 2rem 0;
    grid-template-columns: 6fr 4fr;
  }
}

@media screen and (min-width: 1200px) {
  .berg-components-about-main-item
    .berg-components-about[data-berg-layout='rightPicture']
    .berg-components-about-picture {
    grid-column: 2;
    grid-row: 1 / 4;
  }
}

.berg-components-about-main-item
  .berg-components-about[data-berg-layout='rightPicture']
  .berg-components-about-text {
  grid-row: 1;
  grid-column: 1;
}

.berg-components-about h1 {
  font-size: 30px;
  font-weight: bold;
  color: colors.$primary-dark;
  margin: 1rem 0 1rem 0;
}

.berg-components-about p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  color: colors.$darker-grey;
}

.berg-components-about-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 900;
  text-transform: uppercase;
  max-width: 100%;
  margin: 35px 0;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
  .berg-components-about-nav {
    flex-direction: row;
  }
}

.berg-components-about-nav li {
  margin: 15px 30px;
}

.berg-components-about-nav li a {
  text-decoration: none;
  white-space: pre-line;
  color: colors.$grey;
}

.berg-components-about-nav li a[data-berg-active='true'] {
  color: colors.$primary-dark;
  font-weight: 800;
}

.berg-components-about h2 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 1rem 0 1rem 0;
  color: colors.$dark-grey;
}

.berg-components-about-main .berg-components-about-main-item {
  display: none;
}

.berg-components-about-main
  .berg-components-about-main-item[data-berg-active='true'] {
  display: block;
}

.berg-components-about-button {
  text-align: start;
  margin-bottom: 1rem;


  

  & a {
    display: inline-block;
    width: 180px;
    height: 64px;
    line-height: 4rem;
    border-radius: 2px;
    background-color: colors.$primary-light;
    color: colors.$white;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-top: 3rem;
    text-decoration: none;

    &.is-season{
      background-color: colors.$light-grey ;
      color: colors.$darker-grey ;
    }
  }



  & > .berg-components-tooltip .t-top:before {
    bottom: 58%;
    left: 21%;
    margin-bottom: 9px;
    transform: translateX(-50%);

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      bottom: 62%;
      left: 26%
    }
  }

  & > .berg-components-tooltip .t-top:after {
    bottom: 55%;
    left: 20%;
    margin-bottom: 9px;
    transform: translateX(-50%);

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      left: 26%
    }
  }
}

.about-picture {
  overflow: hidden;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
