@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-steps-axis {
  display: flex;
  justify-content: center;

  &__separator {
    position: relative;
    min-width: 8px;
    width: 130px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      min-width: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 10px;
      left: 50%;
      width: calc(100% + 2px);
      transform: translateX(-50%);
      height: 3px;
      background-color: colors.$gray-300;

      @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
        top: 14px;
      }
    }
  }
}

.steps-axis-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 57px;
  max-width: 86px;
  color: colors.$gray-400;

  @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
    min-width: 64px;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
    min-width: 86px;
  }

  &[role='button'] {
    color: colors.$gray-500;

    &,
    div {
      cursor: pointer;
    }

    &:hover {
      color: colors.$gray-600;
    }
  }

  &[role='button'] &__number {
    background-color: colors.$gray-400;
  }

  &[role='button']:hover &__number {
    background-color: colors.$gray-500;
  }

  &__number {
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-bottom: 10px;
    color: colors.$white;
    background-color: colors.$gray-300;
    font-size: 12px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      font-size: 16px;
      width: 32px;
      height: 32px;
    }
  }

  &__title {
    font-size: 10px;
    hyphens: auto;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      hyphens: none;
      font-size: 12px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      font-size: 14px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      white-space: nowrap;
    }
  }

  &--active {
    color: colors.$polana-red;
  }

  &--active &__number {
    background-color: colors.$polana-red;
  }
}
