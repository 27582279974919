@use 'src/styles/colors';

.berg-components-spinner {
  width: 30px;
  height: 30px;
  border: 5px dotted colors.$light-blue;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
