@use 'src/styles/colors';

.berg-components-indicator-carousel {
  position: relative;

  & > div {
    transition: opacity 0.5s linear;

    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
      padding: 4px;
      width: 18px;
      height: 18px;

      &[data-is-active='true'] span {
        background-color: colors.$orange-500;
      }

      span {
        display: block;
        width: 10px;
        height: 10px;
        border: 2px solid colors.$white;
        border-radius: 50%;
        background-color: colors.$white;
      }
    }
  }
}
