@use 'src/styles/colors';

.berg-components-media-data {
  position: relative;
  width: clamp(350px, 95vw, 550px);
  max-height: 90vh;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 5px colors.$black-alpha-20;
  // overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  .dialog {
    position: absolute;
    top: 51rem;
    left: 21rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: colors.$primary-dark;

    &__text {
      color: colors.$white;
      font-size: 12px;
      font-weight: 500;
      padding: 8px;
    }

    &__close {
      background: none;
      color: colors.$white;
      position: absolute;
      top: 0;
      right: 11px;
    }
  }
}

.berg-components-media-data-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  height: 80px;
  border-bottom: 1px colors.$light-grey solid;
  background-color: colors.$white;
  border-radius: 15px 15px 0 0;
  z-index: 1;
}

.berg-components-media-data-header-text {
  font-size: 24px;
  align-self: center;
}

.berg-components-media-data-form {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 0 2rem;
  margin: 80px 0 130px 0;
  background-color: colors.$white;
}

.berg-components-media-data-form label {
  align-self: flex-start;
  margin: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
}

.berg-components-media-data-aside {
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
  margin-bottom: 15px;
}

.berg-components-media-data-form input {
  text-align: start;
  border: solid 1px colors.$lighter-grey;
  border-radius: 12px;
  width: 100%;
  height: 56px;
  text-transform: uppercase;
}

.berg-components-media-data-img {
  width: 30px;
  height: 30px;
}

.berg-components-media-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: colors.$white;
  border-radius: 0 0 15px 15px;
  overflow: hidden;

  hr {
    height: 1px;
    border: 1px colors.$light-grey solid;
  }

  button {
    color: colors.$white;
    max-width: 240px;
    height: 67px;
    font-size: 23px;
    font-weight: 500;
    margin: 2rem;
    background-color: colors.$primary-dark;
    text-transform: capitalize;
    text-align: center;
    border-radius: 8px;

    &:disabled {
      opacity: 0.6;
      background-color: colors.$darker-grey;
    }
  }
}

.berg-components-media-data-aside .berg-components-tooltip .t-top::before {
  margin-left: 150px;
  white-space: inherit;
  min-width: 350px;
  max-height: 100px;
  inset-inline-start: auto;
}

.berg-components-media-data-aside
  .error
  .berg-components-tooltip
  .t-top::before {
  margin-inline-start: auto;
  white-space: inherit;
  min-width: 350px;
  max-height: 100px;
  margin-left: -118px;
  inset-inline-start: auto;
}
