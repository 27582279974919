@use 'src/styles/colors';

.berg-pages-passes-and-tickets {
  &__search {
    position: relative;
    z-index: 1;

    h3 {
      font-size: 20px;
      font-weight: 600;
      text-align: start;
      margin: 0 0 20px 95px;
      color: colors.$primary-dark;

      & ~ hr {
        height: 1px;
        border: 1px solid var(--light-grey);
        margin: 20px 0;
      }
    }
  }

  .seasons-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 12px 0;

    .season-tab {
      padding: 12px 0;
      color: colors.$grey;

      &--active {
        background-color: colors.$primary-dark;
        color: colors.$white;
      }

      &__title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
      }

      &__description {
        font-size: 12px;
      }
    }
  }
}
