@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-text-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  &__error-message {
    color: red;
  }

  & input {
    box-sizing: border-box;
    padding: 1rem;
    background: #fcfcfd;
    border: 1px solid #dfdfe6;
    border-radius: 12px;
  }

  & label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    width: 30%;
    padding-right: 1vw;
    text-align: right;
    color: #4e4e4e;
  }

  &[aria-required='true'] label::after {
    content: '*';
    color: colors.$polana-red;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
    width: 90%;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
    width: 70%;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    flex-direction: column;
    align-items: start;

    label {
      line-height: 1.7rem;
      white-space: pre;
      text-align: left;
    }
  }
}

@media screen and (max-width: 415px) {
  .berg-text-input label {
    font-size: 0.78rem;
    text-align: left;
  }
}

@media screen and (max-width: 379px) {
  .berg-text-input label {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 290px) {
  .berg-text-input label {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-text-input:nth-child(9) {
    display: none;
  }
}
