@use 'src/styles/colors';

.berg-components-season-selector {
  display: flex;
  margin-top: 2vw;
}

.berg-components-season-selector-tab {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: colors.$light-grey;
  cursor: pointer;
}

.berg-components-season-selector-tab:first-of-type {
  margin-right: 34px;
}

.berg-components-season-selector-tab[aria-selected='true'] {
  color: colors.$black;
}
