$blue: var(--blue);
$light-blue: var(--light-blue);
$lighter-blue: var(--lighter-blue);
$royal-blue: var(--royal-blue);
$dark-blue: var(--dark-blue);

$orange-500: var(--orange-500);
$dark-orange: var(--dark-orange);

$warning-500: var(--warning-500);

$error-500: var(--error-500);

$pink: var(--pink);
$dirty-pink: var(--dirty-pink);

$darker-grey: var(--darker-grey);
$dark-grey: var(--dark-grey);
$darkish-grey: var(--darkish-grey);
$grey: var(--grey);
$almost-grey: var(--almost-grey);
$medium-grey: var(--medium-grey);
$light-grey: var(--light-grey);
$lighter-grey: var(--lighter-grey);
$lightest-grey: var(--lightest-grey);

$gray-50: var(--gray-50);
$gray-100: var(--gray-100);
$gray-150: var(--gray-150);
$gray-200: var(--gray-200);
$gray-300: var(--gray-300);
$gray-400: var(--gray-400);
$gray-500: var(--gray-500);
$gray-600: var(--gray-600);
$gray-700: var(--gray-700);
$gray-800: var(--gray-800);
$gray-900: var(--gray-900);

$black: var(--black);
$black-alpha-10: var(--black-alpha-10);
$black-alpha-20: var(--black-alpha-20);
$black-alpha-30: var(--black-alpha-30);
$black-alpha-50: var(--black-alpha-50);
$black-alpha-60: var(--black-alpha-60);

$white: var(--white);
$white-alpha-70: var(--white-alpha-70);
$off-white: var(--off-white);

$navy: var(--navy);
$dark-navy: var(--dark-navy);

$green: var(--green);

$primary-dark: var(--primary-dark);
$primary-light: var(--primary-light);
$primary-lighter: var(--primary-lighter);

$very-perry: var(--very-perry);
$light-very-perry: var(--light-very-perry);

$first-calendar-range-bg: rgb(255, 228, 217);
$second-calendar-range-bg: rgb(218, 234, 244);

$success-500: var(--success-500);

$polana-primary: var(--polana-primary);
$polana-red: var(--polana-red);
$polana-dark-red: var(--polana-dark-red);
