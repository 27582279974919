@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-room-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  overflow: hidden;
  color: colors.$gray-700;
  text-align: start;
  margin: 0 -5px;
  cursor: pointer;

  &[aria-disabled='true'] {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: colors.$grey;
      opacity: 0.6;
      z-index: 1;
    }

    &::after {
      content: attr(data-unavailable);
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: colors.$white-alpha-70;
      padding: 15px;
      line-height: 1em;
      border-radius: 10px;
      color: colors.$gray-800;
      z-index: 2;
      font-weight: 500;
      font-size: 20px;

      @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
        top: 50%;
        left: 25%;
      }
    }
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
    margin: 0;
    border-radius: 10px;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
    flex-direction: row;
    flex: 0 0 845px;
    height: 280px;
  }

  &__cover-image {
    height: 100%;
    overflow: hidden;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      width: 50%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__content {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: auto 1fr;
    height: auto;
    min-height: 260px;
    padding: 0 0 20px 0;
    cursor: pointer;

    h4 {
      grid-column: 1 / 3;
      text-align: start;
      font-weight: 600;
      font-size: 20px;
      cursor: pointer;

      // @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      //   margin-bottom: 12px;
      // }
    }

    p {
      grid-column: 1 / 3;
      text-align: start;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      justify-items: start;
      grid-template-rows: auto auto 1fr auto;
      width: 50%;
      padding: 20px 0px 20px 20px;
      min-height: 280px;
    }
  }

  &__bed-types {
    grid-column: 1 / 3;
    font-weight: 500;
    font-size: 12px;

    div {
      display: flex;
      line-height: 21px;

      img {
        margin-right: 0.75em;
        cursor: pointer;
      }

      span {
        margin-right: 0.5em;
        cursor: pointer;
      }
    }
  }

  &__price {
    grid-column: 1 / 3;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    span:nth-child(1) {
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;
      margin-right: 0.2em;
      cursor: pointer;
    }

    span:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;
      color: colors.$gray-500;
      cursor: pointer;
    }
  }

  &__price-for-all-people{
    grid-column: 1 / 3;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      margin-left: -149px;
    }
  }

  &__availability {
    grid-column: 1 / 4;
    color: colors.$orange-500;
    font-size: 12px;
    font-weight: 500;
    // margin-left: 20px;
    cursor: pointer;
    // padding: 5px 0;
    

    &::before {
      content: '';
      display: inline-block;
      margin-right: 8px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: colors.$orange-500;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      grid-column: 2;
      grid-row: 5;
      justify-self: center;
    }
  }

  .berg-button {
    grid-column: 1 / 3;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      grid-column: 1;
    }
  }
}
