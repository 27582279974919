@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-banner-wrapper {
  position: absolute;
  bottom: 19%;
  left: 30vw;
  right: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 85px;
  justify-content: center;
}

.berg-components-banner-wrapper p {
  line-height: 1.4rem;
}

.berg-components-banner-wrapper-text {
  color: colors.$white;
  font-size: 14px;
}

.berg-components-banner {
  width: 98%;
  height: 70%;
  display: flex;
  justify-content: space-around;
  border: 3px solid colors.$white;
  border-radius: 20px;
  overflow: hidden;
  /* background-color: #fff; */
}

.berg-components-banner div {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: skew(-30deg);  */
}

.berg-components-banner-text {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  /* transform: skew(30deg);  */
}

.berg-components-banner-text span {
  color: colors.$orange-500;
}

.berg-components-banner-text-offer {
  font-size: 14px;
  /* transform: skew(30deg); */
}

.berg-components-banner-offer-over-time {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  background-color: colors.$white;
  color: colors.$primary-dark;
}

.berg-components-banner-offer-over-time::after {
  position: absolute;
  top: 0;
  right: -9%;
  content: '';
  height: 100%;
  width: 100px;
  background-color: colors.$white;
  transform: skew(-30deg);
  z-index: -5;
}

.berg-components-banner-offer-price {
  width: 40%;
  color: colors.$white;
}

.berg-components-banner-buy-pass {
  position: relative;
  width: 35%;
  background-color: colors.$white;
  color: colors.$primary-dark;
}

.berg-components-banner-buy-pass::after {
  position: absolute;
  top: 0;
  left: -9%;
  content: '';
  height: 100%;
  width: 100px;
  background-color: colors.$white;
  transform: skew(-30deg);
  z-index: -5;
}

.berg-components-banner img {
  width: 0;
  height: 0;
}

@media screen and (min-width: 1058px) {
  .berg-components-banner img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-components-banner-wrapper {
    bottom: 25px;
    left: 0;
    right: 0;
    width: 100%;
    height: 105px;
    padding: 0 8px;
  }

  .berg-components-banner {
    width: 100%;
    height: 43px;
  }

  .berg-components-banner-text {
    font-size: 20px;
  }

  .berg-components-banner-text-offer {
    font-size: 10px;
  }

  .berg-components-banner-text {
    font-size: 18px;
    font-weight: 700;
  }

  .berg-components-banner-wrapper p {
    line-height: 1;
  }

  .berg-components-banner-wrapper-text {
    font-size: 10px;
    text-align: start;
  }
}

@media screen and (min-width: map-get(vars.$breakpoints, 'small')) and (max-width: map-get(vars.$breakpoints, 'large')) {
  .berg-components-banner-wrapper {
    bottom: 85px;
    left: 16vw;
    right: 16vw;
    width: 70%;
    height: 105px;
    padding: 0 8px;
  }

  .berg-components-banner {
    width: 100%;
    height: 43px;
  }

  .berg-components-banner-text {
    font-size: 20px;
  }

  .berg-components-banner-text-offer {
    font-size: 10px;
  }

  .berg-components-banner-text {
    font-size: 18px;
    font-weight: 700;
  }

  .berg-components-banner-wrapper p {
    line-height: 1;
  }

  .berg-components-banner-wrapper-text {
    font-size: 10px;
    text-align: start;
  }
}
