@use 'src/styles/mixins';
@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-pages-activities {
  padding: 10px 0;
  width: 100%;

  @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
    padding: 30px 0;
  }

  &__header {
    &--accommodation {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/accommodation_header.jpg');
    }

    &--winter-lift {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/ski-slopes.jpg');
    }

    &--summer-lift {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/summer-lift.jpg');
    }

    &--rent {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/interior_of_rental.jpg');
    }

    &--school {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/ski-school.jpg');
    }

    &--therms {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/therms.jpg');
    }

    &--parking {
      @include mixins.subpage-header;
      background-image: url('../../assets/images/parking.jpg');
    }
  }

  &__parking {
    padding: 0 0 20px 0;
    z-index: 1;
    position: relative;
    z-index: 1;

    & > hr {
      height: 1px;
      border: 1px solid colors.$light-grey;
      margin: 20px 0;
    }

    .berg-components-filter-bar {
      p:last-of-type {
        display: none;
      }
    }

    .berg-components-tooltip {
      display: none;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: start;
    margin: 0 20px 20px 24px;
    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      font-size: 30px;
      font-weight: 800;
      text-align: start;
      margin: 0 0 20px 0px;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: stretch;
    padding: 0 20px;
    margin-right: -13px;
    margin-bottom: -13px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
      display: flex;
      justify-content: space-between;
      margin-right: -23px;
      margin-bottom: -23px;

      & > * {
        margin-right: 23px;
        margin-bottom: 23px;
      }
    }

    & > * {
      margin-right: 30px;
      margin-bottom: 13px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    & > p {
      font-size: 14px;
      font-weight: 500;
      text-align: start;
      padding: 10px 40px;

      & > a {
        text-decoration: none;
        color: colors.$primary-dark;
        font-weight: 700;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      margin-top: 45px;
    }
  }

  &__button {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 60px;
    color: colors.$darkish-grey;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.1rem;
    text-decoration: none;
    border-radius: 2px;
    background-color: colors.$light-grey;
    cursor: pointer;

    span {
      white-space: pre-line;
      text-align: left;
      margin-left: 14px;
    }

    &.active {
      color: colors.$white;
    }

    &--tickets.active {
      background-color: #8096e0;
    }

    &--therms-tickets.active {
      background-color: #f97171;
    }

    &--accommodation.active {
      background-color: #1ba7e0;
    }

    &--rental-ski.active {
      background-color: #59b2bc;
    }

    &--ski-school.active {
      background-color: #669ebe;
    }

    &--rental-bike.active {
      background-color: #f78c5e;
    }

    &--parking.active {
      background-color: colors.$polana-primary;
    }
  }

  &__accommodation {
    h3 {
      margin: 20px 0;
      font-weight: 700;
      font-size: 16px;
      text-align: start;

      @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
        margin: 25px 0;
      }

      &[data-available='false'] {
        color: colors.$orange-500;

        span {
          font-weight: 500;
          margin-left: 0.25em;
        }
      }
    }

    &-placeholder {
      margin-top: 20px;
      object-fit: contain;
      width: 100%;
      max-width: 440px;
    }

    &--discount {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      color: colors.$primary-dark;
      font-size: 18px;
      font-weight: 500;
      padding-top: 15px;
      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        font-size: 13px;
        font-weight: 400;
        margin-left: 3px;
        text-align: start;
        gap: 10px;
        margin-bottom: 35px;
      }
    }

    &--discount-fullpage {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      color: colors.$primary-dark;
      font-size: 18px;
      font-weight: 500;
      padding-top: 55px;
      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        font-size: 13px;
        font-weight: 400;
        margin-left: 3px;
        text-align: start;
        gap: 10px;
        margin-bottom: 35px;
        padding-top: 0;
      }
    }
  }

  &__back-buttons {
    position: absolute;
    left: 90px;
    margin: 10px 0;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: none;
    }
  }

  &__back-mobile-buttons {
    display: none;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      z-index: 1;
      position: absolute;
      left: 34px;
      top: 738px;
      display: flex;
    }
  }

  &__rooms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-top: 20px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      gap: 75px;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .activities-calendar {
    $legend-colors: 1 colors.$first-calendar-range-bg,
      2 colors.$second-calendar-range-bg, 3 colors.$polana-primary;

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding: 12px;
    border-radius: 8px;
    background-color: colors.$polana-primary;

    &__middle-wrapper {
      border-radius: 8px;
      background-color: colors.$white;
      padding: 0 16px 16px 16px;
    }

    &__legend {
      font-family: 'Roboto';
      font-size: 12px;
      color: colors.$grey;
      text-align: start;

      @each $index, $color in $legend-colors {
        div:nth-child(#{$index}) {
          display: flex;
          align-items: center;
          height: 26px;
          line-height: 1em;

          span {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: $color;
            margin: 0 0.5em;
          }
        }
      }
    }
  }

  &__parking {
    &--info {
      text-align: start;
      p {
        color: var(--dark-gray);
        font-size: 14px;
        font-weight: 500;
      }
    }
    &--aquapark {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      color: colors.$primary-dark;
      text-align: start;
      p {
        color: var(--dark-gray);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.berg-pages-activities-paragraph {
  padding: 1rem;
  text-align: left;
  font-weight: 500;
  font-family: Montserrat;
  font-size: 14px;
  span {
    color: colors.$primary-dark;
    font-weight: 700;
  }
}

.berg-pages-activities-button {
  text-decoration: none;
  display: flex;
  align-self: self-end;
  margin-top: 30px;

  &__img {
    width: 112px;
    height: 93px;
    background-color: colors.$light-very-perry;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 344px;
    height: 93px;
    background-color: colors.$white;
    border-radius: 0 10px 10px 0;
  }
}
