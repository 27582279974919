@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-tabs {
  display: block;
  margin-top: 15px;
}

.tabs-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .tabs-buttons {
    gap: 20px;
    padding: 50px;
  }
}

.tabs-button {
  flex: 0 1 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  padding: 0 15px;
  color: colors.$black;
  font-size: 14px;
  font-weight: 500;
  background-color: colors.$lightest-grey;
  text-decoration: none;
  cursor: pointer;
  border-radius: 15px;
  white-space: nowrap;
}

// @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
//   .tabs-button {
//     height: 80px;
//   }
// }

.tabs-button.active {
  background-color: colors.$primary-dark;
  color: colors.$white;
}
