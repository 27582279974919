@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';

.berg-page-landingpage {
  &__header{
    @include mixins.subpage-header;
    background-image: url('../../../public/assets/landingpage/landingpage-swimmingpool.png');
    margin-bottom: 4rem; 

    h1 {
      width: 94vw;
    }

    &--promo {
      position: absolute;
      top: 55%;
      left: 45%;
      background-color: colors.$white;
      color: colors.$primary-dark;
      border-radius: 2px;
      font-size: 30px;
      font-weight: 300;
      padding: 10px;
      transform: translate(max(-571px, -40vw), 47%);
    }
  }



  &__promo-desc {
    display: flex;
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    

    p {
      font-size: 16px;
      font-weight: 400;
      text-align: start;
      overflow: auto;
      color: colors.$dark-grey;
    }

    li {
      font-size: 16px;
      font-weight: 400;
      text-align: start;
      list-style-type: disc;
      margin-left: 1.2em;
    }

    &--button {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      > div {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          width: 20px;
        }
        
        a {
            text-decoration: none;
            color: colors.$primary-dark;
            font-size: 18px;
            font-weight: 700;
          }
      }
    }
  }

  & .berg-components-arrows-carousel-container {
    width: 150%;
  }

  &__price-list {
    text-align: start;
    margin: 75px 0 15px 0;
  

    h2 {
      font-size: 30px;
      font-weight: 700;
      color: colors.$gray-700;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: colors.$dark-grey;
    }
  }

  .berg-styles-pricing-table {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }

  .berg-components-tourist-attractions {
    margin-top: 5vw;
  }
}

.berg-components-landingpage-form {
  background-color: white;
  padding: 20px 0;
  margin-top: -64px;

  h1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: colors.$primary-dark;
  }

  form {
    padding: 0 240px;
    display: flex;
    flex-direction: column;
  }

  &__input {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: start;
    -webkit-align-items: start;
    margin-top: 30px;
  }

  &__input input, textarea {
    background: none;
    border-bottom: 1px solid colors.$gray-500;
    height: 36px;
    width: 100%;
    text-align: start;
  }

  &__input input::placeholder, textarea::placeholder {
    color: colors.$gray-500;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;
  }

  .berg-checkbox {
    margin-top: 10px;
    
    label {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: colors.$gray-700;
    }
  }

  .berg-button__primary, .berg-components-tooltip{
    align-self: end;
    margin: 30px 0;
  }
}

.berg-page-landingpage-thanks p {
  font-size: 35px;
  font-weight: 400;
  text-align: center;
  color: colors.$primary-dark;
  margin: -34px 0 30px 0;
  line-height: 52px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  /* Safari only override */
  ::i-block-chrome,.berg-components-landingpage-form__input {
    display: -webkit-box;
    -webkit-box-pack: start; /* justify-content */
    -webkit-box-align: start; /* align-items */
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-page-landingpage {
    &__header {
    
      h1 {
        font-weight: 600;
        width: 100vw;
      }

      &--promo {
        font-size: 13px;
        font-weight: 500;
        transform: translate(max(-140px, -40vw), 74%);
        padding: 8px;
      }
    }

    &__promo-desc {
      display: flex;
      flex-direction: column;
      gap: 15px;

      > div {
        margin: 0 15px;
      }

      &--button {
        flex-direction: column;
        gap: 15px
      }
    }

    .berg-components-arrows-carousel-container {
      width: auto;
    }

    &__price-list {
      text-align: center;
      margin: 20px 0 15px 0;
    }

    .berg-styles-pricing-table {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .berg-styles-pricing-table table{
      min-width: auto;
    }
  }

  .berg-components-landingpage-form {
    h1 {
      font-size: 24px;
      padding: 0 12px
    }
    form {
      padding: 0 24px;
    }

    .berg-button__primary, .berg-components-tooltip{
      align-self: center;
    }

    [data-tooltip].t-top:before {
      white-space: break-spaces;
    }
  }

  .berg-page-landingpage-thanks p {
    font-size: 16px;
    line-height: 33px;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) and (min-width: map-get(vars.$breakpoints, 'small')) {
  .berg-page-landingpage {
    margin-top: -15px;

    &__header {
      h1 {
        font-weight: 600;
        width: 94vw;
      }

      &--promo {
        font-size: 18px;
        font-weight: 500;
        transform: translate(max(-308px, -40vw), 60%);
      }
    }

    &__promo-desc {
      display: flex;
      flex-direction: column;
      gap: 15px;

      > div {
        margin: 0 15px;
      }

      &--button {
        flex-direction: column;
        gap: 15px
      }
    }

    .berg-components-arrows-carousel-container {
      width: auto;
    }

    &__price-list {
      text-align: center;
      margin: 20px 0 15px 0;
    }

    .berg-styles-pricing-table {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .berg-styles-pricing-table table{
      min-width: auto;
    }
  }

  .berg-components-landingpage-form {
    form {
      padding: 0 49px;
    }
  }

  .berg-page-landingpage-thanks p {
    font-size: 30px;
  }
}

