.berg-components-ski-slope {
  margin-top: 1rem;
}

.berg-components-ski-slope div {
  padding-top: 1rem;
}

.berg-components-ski-slope div label {
  padding-left: 1rem;
}
