@use 'src/styles/colors';

.berg-components-about-region-additional-information {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    'open qr'
    'localization qr';
}

.berg-components-about-region-additional-information-line {
  height: 1px;
  border: 1px colors.$light-grey solid;
  margin-top: 10px;
}

.berg-components-about-region-additional-information-items-open {
  grid-area: open;

  display: flex;
  align-items: center;
  margin: 15px;
}

.berg-components-about-region-additional-information-items-localization {
  grid-area: localization;

  display: flex;
  align-items: center;
  margin: 15px;
}

.berg-components-about-region-additional-information-items-qr {
  grid-area: qr;

  display: flex;
  align-items: center;
  margin: 15px;
  justify-self: end;
}

.berg-components-about-region-additional-information-items-open label {
  margin-left: 15px;
}

.berg-components-about-region-additional-information-items-localization label {
  margin-left: 15px;
  white-space: pre-line;
}

.berg-components-about-region-additional-information-items-qr div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.berg-components-about-region-additional-information-items-qr label {
  white-space: pre-line;
  text-align: center;
}
