@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-pages-cart-data {
  &__footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 775px;

    label[role='placeholder'] {
      width: 28%;
    }

    .berg-checkbox {
      flex: 1;
      white-space: nowrap;
    }

    p {
      margin-left: auto;
      font-weight: 500;
      font-size: 16px;

      &::before {
        content: '* ';
        color: colors.$polana-red;
      }
    }

    button {
      text-transform: uppercase;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      width: 90%;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
      width: 70%;
    }
  }

  &__phone-prefix {
    width: 4rem;
    margin: 0 18px 0 0;
  }

  label[for='phone'] {
    width: 42%;
  }

  &__phone-input {
    display: none;
  }
}

.berg-components-info {
  max-width: 471px;
  border-radius: 5px;
  background-color: colors.$blue;

  display: flex;
  padding: 3px 7px;
  align-items: center;

  p {
    color: colors.$white;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
  }
}

.quick-form__buttons {
  & > div:last-child {
    display: flex;
    gap: 2.25rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.25rem !important;
  }
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-pages-cart-data__phone-input {
    width: 73%;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-pages-cart-data__phone-input {
    display: block;
    .berg-text-input {
      display: grid;
      grid-template-areas:
        'label label'
        'input_prefix input_tel';
    }
    label {
      grid-area: label;
    }
  }

  .berg-pages-cart-data__footer {
    flex-direction: column-reverse;
    gap: 12px;

    p {
      text-align: start;
      margin-left: 0;
    }
  }
}
