@use 'src/styles/colors';

.berg-components-arrows-carousel-container {
  display: flex;
  flex-direction: column;
  margin: 0 24px;

  .berg-components-arrows-carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
  }

  .berg-components-arrows-carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .berg-components-arrows-carousel-content {
    display: flex;
    align-items: center;
    transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
    -ms-overflow-style: none;
    /* hide scrollbar in IE and Edge */
    scrollbar-width: none;
    /* hide scrollbar in Firefox */
  }

  /* hide scrollbar in webkit browser */

  .berg-components-arrows-carousel-content::-webkit-scrollbar {
    display: none;
  }

  .berg-components-arrows-carousel-content > * {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .carousel-left-arrow-button,
  .carousel-right-arrow-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 48px;
    height: 48px;
    border-radius: 2px;
    background-color: colors.$primary-light;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .carousel-left-arrow-button::after,
  .carousel-right-arrow-button::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: colors.$white;
    transition: opacity 150ms linear;
    opacity: 0;
  }

  .carousel-left-arrow-button:hover::after,
  .carousel-right-arrow-button:hover::after {
    opacity: 0.3;
  }

  .carousel-left-arrow-button {
    left: 0;
    transform: translate(-50%, -50%);
  }

  .carousel-right-arrow-button {
    right: 0;
    transform: translate(50%, -50%);
  }

  .carousel-left-arrow {
    transform: rotate(180deg);
    transform-origin: 45%;
  }
}
