@use 'src/styles/colors';

.berg-components-calendar {
  width: 360px;
  padding: 0 15px 15px 15px;
  overflow: hidden;

  input {
    display: none;
  }

  &__nav {
    margin: 0 -15px;
    padding: 0 15px;
    color: colors.$black;
    height: 50px;
    position: relative;

    button {
      position: absolute;
      cursor: pointer;
      left: 10px;
      font-size: 32px;
      line-height: 1;
      top: 16px;
      width: 30px;
      text-align: center;
      display: inline-block;
      color: colors.$black-alpha-60;
      user-select: none;
      background: none;
      &:hover {
        color: colors.$black-alpha-20;
      }
      &:last-child {
        left: auto;
        right: 10px;
      }
    }

    span {
      margin: 0;
      position: absolute;
      left: 40px;
      right: 40px;
      text-align: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 20px;
      line-height: 67px;
      user-select: none;
      text-transform: capitalize;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    color: colors.$error-500;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    padding: 2px;
    margin-top: 6px;
    gap: 15px;

    & svg {
      height: 2em;
    }
  }
}

.calendar-days {
  font-size: 0;

  span {
    width: 14.24361%;
    min-height: 44px;
    line-height: 44px;
    display: inline-block;
    text-align: center;
    user-select: none;
    margin: 8px 0;
    position: relative;
    font-size: 16px;

    &:not(.disabled) {
      cursor: pointer;
    }

    &:not(.label):hover {
      background-color: colors.$primary-lighter;
      color: colors.$black;
      border-radius: 8px;
    }

    &.label {
      font-size: 16px;
      cursor: initial;
      color: colors.$grey;
      border-bottom: 1px solid colors.$light-grey;
    }

    &.active {
      border-radius: 8px;
      font-weight: 700;
    }

    &.between {
      border-radius: 0;
    }

    &.start {
      border-radius: 8px 0 0 8px;

      &.end {
        border-radius: 8px;

        &:after {
          display: none;
        }
      }
    }

    &.end {
      border-radius: 0 8px 8px 0;
    }
  }

  &__bg-layer {
    span {
      font-size: 0;

      &.between:nth-child(7n):after,
      &.start:nth-child(7n):after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 20px;
        pointer-events: none;
      }

      &.between:nth-child(7n + 1):after,
      &.end:nth-child(7n + 1):after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 20px;
        pointer-events: none;
      }
    }

    &--first span {
      &.active {
        background-color: colors.$lightest-grey;
      }

      &.start,
      &.between,
      &.end {
        background-color: colors.$primary-light;
      }

      &.between:nth-child(7n):after,
      &.start:nth-child(7n):after {
        background-color: colors.$primary-light;
      }

      &.between:nth-child(7n + 1):after,
      &.end:nth-child(7n + 1):after {
        background-color: colors.$primary-light;
      }
    }

    &--second span {
      &.start,
      &.between,
      &.end {
        background-color: colors.$orange-500;
      }

      &.between:nth-child(7n):after,
      &.start:nth-child(7n):after {
        background-color: colors.$orange-500;
      }

      &.between:nth-child(7n + 1):after,
      &.end:nth-child(7n + 1):after {
        background-color: colors.$orange-500;
      }
    }
  }

  &__numbers-layer span {
    &.active {
      font-weight: 700;
    }

    
    &.disabled {
      color: colors.$grey;
    }

    &.start,
    &.between,
    &.end {
      color: colors.$white;
    }
  }

  &[aria-disabled='true'] {
    pointer-events: none;
  }

  &__days {
    position: relative;
  }

  &__bg-layer {
    position: absolute;
    inset: 0;
  }
}
