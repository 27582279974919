.berg-components-pool-description {
  display: grid;
  justify-content: start;
  align-items: center;
  row-gap: 12px;
  column-gap: 16px;
  margin-top: 22px;
}

.berg-components-pool-description svg {
  grid-column: 1;
  justify-self: center;
}

.berg-components-pool-description span {
  grid-column: 2;
}
