@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-season-header-wrapper {
  position: relative;
  height: 60vh;
  margin-bottom: 60px;
  background: url('../../assets/images/summer-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.berg-components-season-header {
  position: relative;
  height: 100%;
  overflow: visible;
  width: 50vw;

  &__alerts {
    position: absolute;
    bottom: 60px;
    left: 50%;
    width: 95vw;
    transform: translateX(-50%);
    max-width: 1320px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      width: 80vw;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
      width: 60vw;
    }
  }
}

.berg-components-season-header::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc((7.5vw + 3px) * -1);
  clip-path: polygon(0 0, calc(100% - 15vw) 0, 100% 100%, 0% 100%);
  background: colors.$white;
}

.season-hero-left-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: -7.5vw;
  clip-path: polygon(0 0, calc(100% - 15vw) 0, 100% 100%, 0% 100%);
  background: url('../../assets/images/winter-background.jpg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 100vmax auto;
}

.season-hero-cursor {
  position: absolute;
  top: 50%;
  left: 50vw;
  transform: translate(-40px, -40px);
  content: '';
  height: 80px;
  width: 80px;
  background-image: url('../../assets/picture/header-handle.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 100%;
  z-index: 2;
  cursor: col-resize;
  touch-action: none;
}

.season-hero-left-header,
.season-hero-right-header {
  position: absolute;
  color: colors.$white;
  font-size: 5rem;
  font-weight: 200;
  line-height: 1em;
  top: 50%;
  left: 50vw;
}

.season-hero-left-header {
  transform: translate(-335px, -50%);
  text-align: end;
  white-space: pre-line;
}

.season-hero-right-header {
  transform: translate(90px, -50%);
  text-align: start;
  white-space: pre-line;
}

@media screen and (max-width: 526px) {
  .season-hero-left-header,
  .season-hero-right-header {
    font-size: 25px;
    font-weight: 200;
  }

  .season-hero-cursor {
    height: 54px;
    width: 62px;
    top: 45%;
    padding-left: 8px;
    background-position: right;
  }

  .season-hero-right-header {
    transform: translate(40px, -97%);
  }

  .season-hero-left-header {
    transform: translate(-124px, -97%);
  }
}

@media screen and (min-width: 526px) and (max-width: 680px) {
  .season-hero-left-header,
  .season-hero-right-header {
    font-size: 50px;
    font-weight: 200;
  }

  .season-hero-cursor {
    height: 70px;
    width: 70px;
    top: 39%;
  }

  .season-hero-right-header {
    transform: translate(70px, -97%);
  }

  .season-hero-left-header {
    transform: translate(-252px, -97%);
  }
}

@media screen and (min-width: 680px) and (max-width: map-get(vars.$breakpoints, 'large')) {
  .season-hero-left-header,
  .season-hero-right-header {
    font-size: 80px;
    font-weight: 200;
  }

  .season-hero-cursor {
    height: 70px;
    width: 70px;
    top: 39%;
  }

  .season-hero-right-header {
    transform: translate(70px, -97%);
  }

  .season-hero-left-header {
    transform: translate(-336px, -97%);
  }
}
