@use 'src/styles/colors';

.responsive-nav {
  position: fixed;
  display: block;
  top: 50px;
  width: 100%;
  height: calc(100% - 50px);
  color: colors.$black;
  background-color: colors.$white;
  overflow-y: scroll;
  padding-left: 0;

  .berg-components-menu-item {
    border-bottom: 1px solid colors.$lighter-grey;
    height: auto;
  }

  .berg-components-menu-item a,
  .berg-components-menu-item button,
  .navigation-weather {
    height: 54px;
    padding: 0 30px;
    justify-content: start;
  }

  .berg-components-navigation-dropdown {
    display: block;
  }

  .berg-components-navigation-dropdown button,
  .berg-components-language-dropdown button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 480px;
    padding: 0 30px;
    height: 50px;
  }

  .berg-components-navigation-dropdown button svg,
  .berg-components-language-dropdown button svg {
    color: colors.$primary-light;
  }

  .berg-components-navigation-dropdown-list {
    position: static;
    background: colors.$primary-dark;
  }

  .berg-components-navigation-dropdown-list li {
    color: colors.$white;
    border-bottom: 1px solid colors.$white;
  }

  .navigation-weather img {
    filter: invert(100%);
  }
}
