@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-thanks {
  width: 632px;
  height: 81px;
  background-color: colors.$primary-dark;

  display: grid;
  grid-template-columns: 120px auto;
  align-items: center;

  &__image {
    place-self: center;
    width: 108%;
  }

  & p {
    color: colors.$white;
    text-align: start;
    font-size: 18px;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-thanks {
    width: 100%;
    grid-template-columns: 95px auto;

    & p {
      color: colors.$white;
      text-align: start;
      font-size: 14px;
    }
  }
}
