@use 'src/styles/colors';
@use 'src/styles/vars';

@keyframes btn-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.berg-components-sticky-cta {
  position: fixed;
  display: grid;
  place-items: center;
  bottom: 50px;
  right: 50px;
  width: 156px;
  height: 156px;
  border-radius: 50%;
  padding: 15px;
  text-transform: uppercase;
  color: white;
  font-weight: 800;
  background: colors.$primary-light;
  cursor: pointer;
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s;
  text-decoration: none;
  z-index: 2;
}
.berg-components-sticky-cta a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124%;
  height: 124%;
  border-radius: 50%;
  padding: 10% 34%;
  text-decoration: none;
}

.berg-components-sticky-cta:hover {
  transform: scale(1.1);
}

.berg-components-sticky-cta:focus,
.berg-components-sticky-cta:active {
  animation: btn-scale 0.15s forwards linear;
}

@media (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-components-sticky-cta {
    width: 100px;
    height: 100px;
    bottom: 25px;
    right: 25px;
    font-size: 14px;
  }
}
