@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-language-dropdown {
  position: relative;
  background: colors.$white;
  color: colors.$black;
}

.berg-components-language-dropdown button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0 14px;
}

.berg-components-language-dropdown button svg {
  pointer-events: none;
}

.berg-components-language-dropdown-list {
  display: none;
  position: absolute;
  left: 0;
  top: 54px;
  background: colors.$white-alpha-70;
  color: colors.$black;
}

.berg-components-language-dropdown-list-item {
  padding: 18px;
  cursor: pointer;
  line-height: 0;
}

.berg-components-language-dropdown-list-item:hover {
  background-color: colors.$white;
}

.berg-components-language-dropdown-list-item:not(:last-child) {
  border-bottom: 1px solid colors.$white;
}

.berg-components-language-dropdown-list-item.selected {
  background: colors.$white;
}

.berg-components-language-dropdown-list li a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
}

.berg-components-language-dropdown-list li a:hover {
  background: colors.$black-alpha-20;
}

.berg-components-language-dropdown-list.visible {
  display: block;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    display: flex;
    flex-direction: row;
  }
}
